// Core markdown based post
import React from "react"
import "./post.css"

import Header from "./header"
import Layout from "./layout"

const Dots = (props) => {
  const { string } = props;
  return (
    <div>
      {/* {string.split('').map(item => <span>-</span>)} */}
    </div>
  )
};

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const { example } = frontmatter;
  return (
    <Layout>
      <div>
        <div>
          <h1>{frontmatter.title}
            <Dots string={frontmatter.title} />
          </h1>
          <div
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      {/* How can i make this iframe clickable? */}
      {example &&
        <React.Fragment>
          <a href={example}>{example}</a>
          <div>
            <iframe width="800px" height="600px" src={example}></iframe>
          </div>
        </React.Fragment>
      }
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        example
      }
    }
  }
`
